import React from 'react';
import Container from 'gatsby-theme-core/src/components/container';

const Post = ({ children }) => (
  <Container>
    {children}
  </Container>
);

export default Post;
