import styled from 'styled-components';

export default styled.div`
  padding: 3rem 0;
  display: grid;
  grid-auto-rows: auto;
  grid-gap: ${(props) => props.theme.grid.columnGap};

  @media all and (min-width: ${({ theme }) => theme.breakingpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
