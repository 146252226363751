import React from 'react';
import styled from 'styled-components';
import PageImage from 'gatsby-theme-core/src/components/page-image';
import Container from 'gatsby-theme-core/src/components/container';

const PageImageContainer = styled(Container)`
  min-height: 12rem;
  display: flex;
  align-items: center;
  color: white;
`;

const Intro = ({ image, title, content }) => image && (
  <PageImage darker image={image}>
    <PageImageContainer>
      {(title || content) && (
        <article>
          {title && (
            <h1>{title}</h1>
          )}
          {content?.childMarkdownRemark?.html}
        </article>
      )}
    </PageImageContainer>
  </PageImage>
);

export default Intro;
