import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'gatsby-theme-core/src/layout/layout';
import Content from 'gatsby-theme-core/src/components/content';

import Preview from '../components/preview';
import Wrapper from '../components/overview/wrapper';
import Container from '../components/overview/container';
import Intro from '../components/overview/intro';

export const pageQuery = graphql`
  query StrapiBlogsOverview($id: String!) {
    page: strapiPagesPages(id: { eq: $id }) {
      content: childStrapiPagesPagesContent {
        childMarkdownRemark {
          html
        }
      }
      title
      image {
        url
        height
        width
        name
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
              breakpoints: [576, 768, 992, 1200, 2400]
            )
          }
        }
      }
      ...PagesPageHead
    }

    posts: allStrapiBlogPosts (
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        ...PostPreview
      }
    }
  }
`;

const Blogs = ({ data: { page, posts } }) => (
  <>
    <Intro {...page} />
    <Container>
      <Content>
        <Wrapper>
          {posts.nodes.map((post) => (
            <Preview fromOverview key={post.id} {...post} />
          ))}
        </Wrapper>
      </Content>
    </Container>
  </>
);

const BlogsLayout = (props) => (
  <Layout {...props.data.page}>
    <Blogs {...props} />
  </Layout>
);

export default BlogsLayout;
